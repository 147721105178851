.skeleton {
  width: 100%;
  height: 100vh;
  height: 100dvh;
  background-color: #eee;
  border-radius: 4px;
  margin-bottom: 10px;
  opacity: 0.7;
  animation: shimmer 1.5s infinite linear;

  @keyframes shimmer {
    0% {
      background-position: -1000px 0;
    }
    100% {
      background-position: 1000px 0;
    }
  }
}
